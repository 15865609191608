import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Combobox as HeadlessUICombobox } from '@headlessui/react';
import { ArrowLeftIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, ButtonVariant } from '@/ui-library/button';
import { cn } from '@/utils/styles';

import { Label } from '../label';
import {
  ComboboxOptionProps,
  NestedComboboxLevel,
  NestedComboboxOption,
  NestedComboboxProps,
  OptionsListProps,
} from './nested-combobox.types';

const styles = {
  base: (height: string) =>
    `block w-full resize-y border-0 px-4 placeholder:text-NeutralLightLightest outline-none text-sm ${height}`,

  state: (disabled: boolean) =>
    disabled ? 'bg-NeutralLightLight text-NeutralLightDarkest' : 'text-NeutralDarkDarkest',

  ring: (disabled: boolean) =>
    cn(
      'ring-inset ring-1 ring-NeutralLightDarkest focus:ring-HighlightMedium focus:ring-[1.5px]',
      !disabled && 'hover:ring-HighlightMedium hover:ring-[1.5px]',
    ),

  rounded: (isOpen: boolean) => cn(isOpen ? 'rounded-t-lg rounded-x-lg' : 'rounded-lg'),
};

const ComboboxOption = ({ option, onSelect, onLoadSubfolders }: ComboboxOptionProps) => (
  <div className='flex items-center justify-between'>
    <HeadlessUICombobox.Option
      value={option}
      className={({ active }) =>
        cn(
          'cursor-default select-none p-3 font-normal rounded-md flex-grow transition-opacity duration-200',
          active
            ? 'bg-NeutralLightLight text-HighlightDarkest font-semibold opacity-100 cursor-pointer'
            : 'text-NeutralDarkDarkest opacity-75',
        )
      }
      onClick={() => onSelect(option)}>
      {({ selected }) => (
        <span className={cn('block truncate', selected && 'font-semibold text-HighlightDarkest')}>{option.label}</span>
      )}
    </HeadlessUICombobox.Option>
    <Button
      variant={ButtonVariant.Tertiary}
      className='ml-2'
      onClick={(e) => {
        e.stopPropagation();
        onLoadSubfolders(option);
      }}>
      <ChevronRightIcon className='w-5 h-5 text-NeutralDarkLightest' />
    </Button>
  </div>
);

const OptionsList = ({ options, onSelect, onLoadSubfolders, animationRef }: OptionsListProps) => (
  <div className='overflow-y-auto max-h-60' ref={animationRef}>
    {options?.map((option) => (
      <ComboboxOption key={option.id} option={option} onSelect={onSelect} onLoadSubfolders={onLoadSubfolders} />
    ))}
  </div>
);

const createSkeletonItems = (currentLevelIndex: number) =>
  Array.from({ length: 4 }, (_, i) => (
    <div
      key={`skeleton-${currentLevelIndex}-${i}`}
      className='flex items-center justify-between p-3 rounded-md bg-NeutralLightLight animate-pulse'>
      <div className='h-4 w-3/4 bg-NeutralLightDark rounded' />
      <ChevronRightIcon className='w-5 h-5 text-NeutralLightDark' />
    </div>
  ));

export const NestedCombobox: React.FC<NestedComboboxProps> = ({
  name,
  label,
  placeholder = 'Select an option...',
  onLevelSelection,
  className = '',
  disabled = false,
  isOptional = false,
  height = 'h-12 py-3.5',
  value: controlledValue = null,
  onChange,
}) => {
  const { setValue, watch } = useFormContext();
  const [levels, setLevels] = useState<NestedComboboxLevel[]>([{ label: 'Root', options: [], loading: true }]);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [query, setQuery] = useState('');
  const [animationParent] = useAutoAnimate();

  const formValue = watch(name);
  const value = controlledValue || formValue;
  const currentLevel = levels[currentLevelIndex];

  useEffect(() => {
    const loadInitialOptions = async () => {
      const initialOptions = await onLevelSelection();
      const uniqueOptions = value ? [value, ...initialOptions.filter((opt) => opt.id !== value.id)] : initialOptions;

      setLevels([
        {
          label: 'Root',
          options: uniqueOptions,
          loading: false,
        },
      ]);
    };

    loadInitialOptions();
  }, [onLevelSelection, value?.id]);

  const handleOptionSelect = (option: NestedComboboxOption) => {
    setValue(name, option);
    onChange?.(option);
  };

  const handleLoadSubfolders = async (option: NestedComboboxOption) => {
    const newLevel = { label: option.label, options: [], loading: true };
    setLevels((prev) => [...prev.slice(0, currentLevelIndex + 1), newLevel]);
    setCurrentLevelIndex((prev) => prev + 1);

    const fetchedOptions = await onLevelSelection(option.id);
    setLevels((prev) => {
      const updatedLevels = [...prev];
      updatedLevels[currentLevelIndex + 1] = { ...newLevel, options: fetchedOptions, loading: false };
      return updatedLevels;
    });
  };

  const handleBack = () => setCurrentLevelIndex((prev) => prev - 1);

  const getFilteredOptions = () =>
    query === ''
      ? currentLevel?.options
      : currentLevel?.options.filter((opt) => opt.label.toLowerCase().includes(query.toLowerCase()));

  const inputClassName = cn(styles.base(height), styles.ring(disabled), className);

  return (
    <div className='flex flex-col gap-2'>
      {label && (
        <div className='flex items-center gap-1'>
          <Label htmlFor={name}>
            <div className='flex items-center gap-1'>
              {label}
              {isOptional && <span className='font-normal text-NeutralDarkLightest'>Optional</span>}
            </div>
          </Label>
        </div>
      )}

      <HeadlessUICombobox as='div' value={value} disabled={disabled}>
        {({ open }) => (
          <div className='relative'>
            <HeadlessUICombobox.Button className='w-full'>
              <div className='relative'>
                <HeadlessUICombobox.Input
                  placeholder={placeholder}
                  className={cn(`${inputClassName} ${styles.rounded(open)}`)}
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={() => value?.label || ''}
                />
                <div
                  className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
                  aria-hidden='true'>
                  <div className={cn('w-5 h-5', disabled ? 'text-NeutralLightDarkest' : 'text-NeutralDarkLightest')}>
                    {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </div>
                </div>
              </div>
            </HeadlessUICombobox.Button>

            <HeadlessUICombobox.Options className='absolute z-10 w-full bg-white text-base shadow-lg focus:outline-none sm:text-sm rounded-b-lg border-x border-b border-HighlightMedium'>
              <div className='p-3 relative min-h-[240px]'>
                {currentLevelIndex > 0 && (
                  <button className='flex items-center gap-2 text-NeutralDarkDarkest text-sm mb-2' onClick={handleBack}>
                    <ArrowLeftIcon className='w-4 h-4 text-HighlightDarkest font-semibold' />
                    <span className='text-xs text-HighlightDarkest font-semibold'>Back</span>
                  </button>
                )}

                <div className='relative'>
                  <div
                    className={cn(
                      'absolute inset-0 w-full transition-opacity duration-200 ease-in-out',
                      currentLevel?.loading
                        ? 'opacity-100 pointer-events-auto delay-150'
                        : 'opacity-0 pointer-events-none delay-0',
                    )}>
                    {createSkeletonItems(currentLevelIndex)}
                  </div>

                  <div
                    className={cn(
                      'transition-opacity duration-200 ease-in-out',
                      currentLevel?.loading
                        ? 'opacity-0 pointer-events-none delay-0'
                        : 'opacity-100 pointer-events-auto delay-150',
                    )}>
                    {getFilteredOptions()?.length === 0 ? (
                      <span className='text-NeutralDarkLightest text-sm ml-1'>No options found</span>
                    ) : (
                      <OptionsList
                        loading={currentLevel?.loading}
                        options={getFilteredOptions()}
                        onSelect={handleOptionSelect}
                        onLoadSubfolders={handleLoadSubfolders}
                        animationRef={animationParent}
                      />
                    )}
                  </div>
                </div>
              </div>
            </HeadlessUICombobox.Options>
          </div>
        )}
      </HeadlessUICombobox>
    </div>
  );
};
